


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Icon } from "vant";

@Component({
  components: {
    Icon,
  },
})
export default class OrderCard extends Vue {
  @Prop({ default: false }) private readonly topBorder!: boolean | string;
  @Prop({ default: "" }) private readonly title!: string;
  @Prop({ default: false }) private readonly topLink!: boolean | string;

  get border(): boolean {
    return this.topBorder !== false;
  }

  get link(): boolean {
    return this.topLink !== false;
  }
}
